import React from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../services/auth';

function Home() {
  const authenticated = isAuthenticated();

  return (
    <div className="text-center py-20">
      <h1 className="text-5xl font-extrabold text-gray-800 mb-4">Welcome to Meowbility</h1>
      <p className="text-2xl text-gray-600 mb-8">Purr-fect your flexibility!</p>
      
      <img 
        src="/home.png" 
        alt="Meowbility Cat" 
        className="mx-auto mb-12"
        style={{ maxWidth: '300px' }}
      />

      <div className="space-y-4 sm:space-y-0 sm:space-x-6">
        {authenticated ? (
          <>
            <Link to="/generate-workout" className="inline-block bg-babyblue-500 hover:bg-babyblue-600 text-white font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105 shadow-lg">
              Generate Workout
            </Link>
            <Link to="/workouts" className="inline-block bg-marigold-500 hover:bg-marigold-600 text-gray-900 font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105 shadow-lg">
              View Workouts
            </Link>
          </>
        ) : (
          <>
            <Link to="/login" className="inline-block bg-babyblue-500 hover:bg-babyblue-600 text-white font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105 shadow-lg">
              Login
            </Link>
            {/* <Link to="/register" className="inline-block bg-marigold-500 hover:bg-marigold-600 text-gray-900 font-bold py-3 px-8 rounded-full transition duration-300 transform hover:scale-105 shadow-lg">
              Register
            </Link> */}
          </>
        )}
      </div>
    </div>
  );
}

export default Home;