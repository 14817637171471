import axios from 'axios';
import { getToken } from './auth';

const API_URL = process.env.REACT_APP_API_URL || '/api';

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getBodyParts = () => api.get('/body-parts');
export const deleteWorkout = (id) => api.delete(`/workouts/${id}`);
export const deleteWorkoutCompletion = (id) => api.delete(`/completed/${id}`);
export const getCompletedWorkouts = () => api.get('/completed');

export default api;