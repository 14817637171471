import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { isAuthenticated } from './services/auth';
import Home from './pages/home';
import Register from './components/register';
import Login from './components/login';
import GenerateWorkout from './pages/generateworkout';
import WorkoutDetail from './pages/workoutdetail';
import Workouts from './pages/workouts';
import CompletedWorkouts from './pages/completedworkouts';
import Stretches from './pages/stretches';
import AddStretch from './pages/addstretch';
import Header from './components/header';
import CreateWorkout from './pages/createworkout';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }
  />
);

function App() {
  return (
    <Router>
      <div className="app-container min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow container mx-auto px-4 py-8">
          <Switch>
            <Route exact path="/" component={Home} />
            {/* <Route path="/register" component={Register} /> */}
            <Route path="/login" component={Login} />
            <PrivateRoute path="/generate-workout" component={GenerateWorkout} />
            <PrivateRoute path="/workouts" exact component={Workouts} />
            <PrivateRoute path="/workouts/:id" component={WorkoutDetail} />
            <PrivateRoute path="/completed-workouts" component={CompletedWorkouts} />
            <PrivateRoute path="/stretches" component={Stretches} />
            <PrivateRoute path="/add-stretch" component={AddStretch} />
            <PrivateRoute path="/create-workout" component={CreateWorkout} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;