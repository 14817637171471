import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import api from '../services/api';

function ProgressBar({ progress }) {
  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
      <div
        className="bg-blue-600 h-2.5 rounded-full transition-all duration-1000 ease-linear"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
}

function WorkoutFlow({ workout, onFinish, history }) {
  const [currentStretchIndex, setCurrentStretchIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (currentStretchIndex < workout.stretches.length) {
      setTimeLeft(workout.stretches[currentStretchIndex].duration);
      setIsActive(false);
      setIsPaused(false);
    }
  }, [currentStretchIndex, workout.stretches]);

  useEffect(() => {
    let timer;
    if (isActive && !isPaused && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0 && isActive) {
      setIsActive(false);
    }
    return () => clearInterval(timer);
  }, [isActive, isPaused, timeLeft]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const handlePauseResume = () => {
    setIsPaused(!isPaused);
  };

  const handleNext = async () => {
    if (currentStretchIndex < workout.stretches.length - 1) {
      setCurrentStretchIndex(currentStretchIndex + 1);
    } else {
      await handleCompleteWorkout();
    }
  };

  const handleCompleteWorkout = async () => {
    if (isSubmitting) {
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      await api.post(`/workouts/${workout.id}/complete`);
      setIsActive(false);
      history.push('/completed-workouts');
    } catch (error) {
      console.error('Error completing workout:', error);
      setError('Failed to mark workout as completed. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const currentStretch = workout.stretches[currentStretchIndex];
  const isExerciseDone = timeLeft === 0 && isActive;

  return (
    <div className="max-w-2xl mx-auto text-center p-6">
      <h2 className="text-3xl font-bold mb-6">Current Stretch</h2>
      <TransitionGroup>
        <CSSTransition key={currentStretchIndex} classNames="stretch" timeout={500}>
          <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
            <h3 className="text-2xl font-semibold text-primary-600 mb-4">{currentStretch.name}</h3>
            {currentStretch.image_url && (
              <img 
                src={currentStretch.image_url} 
                alt={currentStretch.name} 
                className="w-full h-64 object-contain rounded-md mb-4"
              />
            )}
            <ProgressBar progress={(currentStretch.duration - timeLeft) / currentStretch.duration * 100} />
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <p className="text-gray-600">
                  <FontAwesomeIcon icon="person" className="mr-2" />
                  Body Part
                </p>
                <p className="font-semibold">{currentStretch.body_part}</p>
              </div>
              <div>
                <p className="text-gray-600">
                  <FontAwesomeIcon icon="clock" className="mr-2" />
                  Time Left
                </p>
                <p className="font-semibold">{timeLeft} seconds</p>
              </div>
            </div>
            {error && <div className="text-red-600 mb-4">{error}</div>}
            <p className="text-gray-700">{currentStretch.description}</p>
          </div>
        </CSSTransition>
      </TransitionGroup>
      <p className="text-lg mb-6">
        <FontAwesomeIcon icon="list-ol" className="mr-2" />
        Stretch {currentStretchIndex + 1} of {workout.stretches.length}
      </p>
      <div className="flex justify-center space-x-4">
        {!isActive && (
          <button onClick={handleStart} className="btn btn-primary">
            <FontAwesomeIcon icon="play" className="mr-0 md:mr-2" />
            <span className="hidden md:inline">Start</span>
          </button>
        )}
        {isActive && !isExerciseDone && (
          <button onClick={handlePauseResume} className="btn btn-secondary">
            <FontAwesomeIcon icon={isPaused ? "play" : "pause"} className="mr-0 md:mr-2" />
            <span className="hidden md:inline">{isPaused ? 'Resume' : 'Pause'}</span>
          </button>
        )}
        <button 
          onClick={handleNext} 
          className={`btn btn-primary ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isSubmitting}
        >
          <FontAwesomeIcon icon={currentStretchIndex === workout.stretches.length - 1 ? "check" : "arrow-right"} className="mr-0 md:mr-2" />
          <span className="hidden md:inline">
            {currentStretchIndex === workout.stretches.length - 1 ? (isSubmitting ? 'Finishing...' : 'Finish') : 'Next'}
          </span>
        </button>
      </div>
    </div>
  );
}

export default WorkoutFlow;