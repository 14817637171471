import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../services/api';

function ChangeStretchModal({ isOpen, onClose, onStretchChanged, currentStretch }) {
  const [stretches, setStretches] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isOpen && currentStretch) {
      fetchStretches();
    }
  }, [isOpen, currentStretch]);

  const fetchStretches = async () => {
    try {
      const response = await api.get(`/stretches?body_part=${currentStretch.body_part}`);
      setStretches(response.data.filter(s => s.id !== currentStretch.id));
    } catch (error) {
      setError('Failed to fetch stretches. Please try again.');
    }
  };

  const handleStretchSelect = async (stretch) => {
    try {
      await onStretchChanged(stretch);
      onClose();
    } catch (error) {
      setError('Failed to change stretch. Please try again.');
    }
  };

  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" onClick={handleOutsideClick}>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-semibold mb-4">Change Stretch for {currentStretch.body_part}</h3>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <ul className="space-y-2 mb-4">
          {stretches.map(stretch => (
            <li 
              key={stretch.id} 
              className="p-2 hover:bg-gray-100 cursor-pointer rounded"
              onClick={() => handleStretchSelect(stretch)}
            >
              <h4 className="font-semibold">{stretch.name}</h4>
              <p className="text-sm text-gray-600">Duration: {stretch.duration} seconds</p>
            </li>
          ))}
        </ul>
        <button onClick={onClose} className="btn btn-secondary">
          <FontAwesomeIcon icon="times" className="mr-2" />
          Close
        </button>
      </div>
    </div>
  );
}

export default ChangeStretchModal;