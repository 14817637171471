import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import Error from '../components/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AddStretch() {
  const [newStretch, setNewStretch] = useState({ name: '', body_part: '', description: '', duration: 60, llm_description: '' });
  const [error, setError] = useState('');
  const history = useHistory();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewStretch({ ...newStretch, [name]: value });
  };

  const handleAddStretch = async (e) => {
    e.preventDefault();
    try {
      await api.post('/stretches', newStretch);
      history.push('/stretches');
    } catch (error) {
      setError('Failed to add stretch. Please try again.');
    }
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Add New Stretch</h2>
      {error && <Error message={error} />}
      <form onSubmit={handleAddStretch} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={newStretch.name}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="body_part">
            <FontAwesomeIcon icon="person" className="mr-2" />
            Body Part
          </label>
          <input
            type="text"
            id="body_part"
            name="body_part"
            value={newStretch.body_part}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            <FontAwesomeIcon icon="info-circle" className="mr-2" />
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={newStretch.description}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="llm_description">
            <FontAwesomeIcon icon="robot" className="mr-2" />
            LLM Description
          </label>
          <textarea
            id="llm_description"
            name="llm_description"
            value={newStretch.llm_description}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="duration">
            <FontAwesomeIcon icon="clock" className="mr-2" />
            Recommended Time (seconds)
          </label>
          <input
            type="number"
            id="duration"
            name="duration"
            value={newStretch.duration}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button type="submit" className="btn btn-primary">
            Add Stretch
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddStretch;