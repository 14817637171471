import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../services/api';
import Error from '../components/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ListStretches() {
  const [stretches, setStretches] = useState([]);
  const [error, setError] = useState('');
  const [editingStretch, setEditingStretch] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchStretches();
  }, []);

  const fetchStretches = async () => {
    try {
      const response = await api.get('/stretches');
      setStretches(response.data);
    } catch (error) {
      setError('Failed to fetch stretches. Please try again later.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingStretch({ ...editingStretch, [name]: value });
  };

  const handleUpdateStretch = async (e) => {
    e.preventDefault();
    try {
      await api.put(`/stretches/${editingStretch.id}`, editingStretch);
      setEditingStretch(null);
      fetchStretches();
    } catch (error) {
      setError('Failed to update stretch. Please try again.');
    }
  };

  const handleDeleteStretch = async (id) => {
    try {
      const response = await api.delete(`/stretches/${id}`);
      if (response.status === 200) {
        fetchStretches();
      } else {
        setError(`Failed to delete stretch. Server responded with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting stretch:', error);
      setError(`Failed to delete stretch. ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">Stretches</h2>
        <Link 
          to="/add-stretch" 
          className="btn btn-primary text-sm sm:text-base px-2 py-1 sm:px-4 sm:py-2"
        >
          <FontAwesomeIcon icon="plus" className="mr-1 sm:mr-2" />
          <span className="hidden xs:inline">Add New Stretch</span>
          <span className="xs:hidden">Add</span>
        </Link>
      </div>
      {error && <Error message={error} />}
      <ul className="space-y-4">
        {stretches.map((stretch) => (
          <li key={stretch.id} className="bg-white shadow-md rounded-lg p-6">
            {editingStretch && editingStretch.id === stretch.id ? (
              <form onSubmit={handleUpdateStretch}>
                <input
                  type="text"
                  name="name"
                  value={editingStretch.name}
                  onChange={handleInputChange}
                  className="input mb-2"
                  required
                />
                <input
                  type="text"
                  name="body_part"
                  value={editingStretch.body_part}
                  onChange={handleInputChange}
                  className="input mb-2"
                  required
                />
                <textarea
                  name="description"
                  value={editingStretch.description}
                  onChange={handleInputChange}
                  className="input mb-2"
                  required
                />
                <input
                  type="number"
                  name="duration"
                  value={editingStretch.duration}
                  onChange={handleInputChange}
                  className="input mb-2"
                  required
                />
                <button type="submit" className="btn btn-primary mr-2">Save</button>
                <button onClick={() => setEditingStretch(null)} className="btn btn-secondary">Cancel</button>
              </form>
            ) : (
              <div className="flex flex-col md:flex-row justify-between items-start">
                <div className="w-full md:w-2/3 pr-4">
                  <h4 className="text-lg font-semibold text-gray-800">
                    <FontAwesomeIcon icon="running" className="mr-2" />
                    {stretch.name}
                  </h4>
                  <p className="text-gray-600">
                    <FontAwesomeIcon icon="person" className="mr-2" />
                    Body Part: {stretch.body_part}
                  </p>
                  <p className="text-gray-600">
                    <FontAwesomeIcon icon="info-circle" className="mr-2" />
                    Description: {stretch.description}
                  </p>
                  <p className="text-gray-600">
                    <FontAwesomeIcon icon="clock" className="mr-2" />
                    Recommended Time: {stretch.duration} seconds
                  </p>
                  <div className="mt-4">
                    <button onClick={() => setEditingStretch(stretch)} className="btn btn-primary mr-2">
                      <FontAwesomeIcon icon="edit" className="mr-2" />
                      Edit
                    </button>
                    <button onClick={() => handleDeleteStretch(stretch.id)} className="btn btn-secondary">
                      <FontAwesomeIcon icon="trash-alt" className="mr-2" />
                      Delete
                    </button>
                  </div>
                </div>
                <div className="w-full md:w-1/3 mt-4 md:mt-0">
                  {stretch.image_url && (
                    <img 
                      src={stretch.image_url} 
                      alt={stretch.name} 
                      className="w-full h-48 object-contain rounded-md"
                    />
                  )}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ListStretches;