import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../services/api';
import Error from '../components/error';
import WorkoutFlow from '../components/workoutflow';
import ChangeStretchModal from '../components/changestretchmodal';

function WorkoutDetail() {
  const [workout, setWorkout] = useState(null);
  const [error, setError] = useState('');
  const [isWorkoutStarted, setIsWorkoutStarted] = useState(false);
  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false);
  const [selectedStretchIndex, setSelectedStretchIndex] = useState(null);
  const [regeneratingStretches, setRegeneratingStretches] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    fetchWorkout();
  }, [id]);

  const getBackDestination = () => {
    return location.state?.from || '/workouts';
  };

  const getBackButtonText = () => {
    return location.state?.from === '/completed-workouts' ? 'Back to Completed Workouts' : 'Back to Workouts';
  };

  const fetchWorkout = async () => {
    try {
      const response = await api.get(`/workouts/${id}`);
      setWorkout(response.data);
    } catch (error) {
      setError('Failed to fetch workout details. Please try again later.');
      console.error('Error fetching workout:', error);
    }
  };

  const handleCompleteWorkout = async () => {
    try {
      await api.post(`/workouts/${id}/complete`);
      fetchWorkout();
    } catch (error) {
      setError('Failed to mark workout as completed. Please try again.');
      console.error('Error completing workout:', error);
    }
  };

  const handleChangeStretch = (index) => {
    setSelectedStretchIndex(index);
    setIsChangeModalOpen(true);
  };

  const handleStretchChanged = async (newStretch) => {
    try {
      const updatedStretches = workout.stretches.map((s, index) => 
        index === selectedStretchIndex ? newStretch : s
      );
      
      const updatedWorkout = {
        ...workout,
        stretches: updatedStretches,
        duration: updatedStretches.reduce((total, s) => total + s.duration, 0)
      };

      await api.put(`/workouts/${workout.id}`, updatedWorkout);
      
      // Fetch the updated workout
      const response = await api.get(`/workouts/${workout.id}`);
      setWorkout(response.data);
      
      setIsChangeModalOpen(false);
    } catch (error) {
      setError('Failed to update workout. Please try again.');
      console.error('Error updating workout:', error);
    }
  };

  const handleRegenerateImage = async (stretchId) => {
    const confirmRegenerate = window.confirm("Are you sure you want to regenerate this image? This action cannot be undone.");
    
    if (confirmRegenerate) {
      try {
        setRegeneratingStretches(prev => ({ ...prev, [stretchId]: true }));
        const response = await api.post(`/stretches/${stretchId}/regenerate-image`);
        const updatedWorkout = {
          ...workout,
          stretches: workout.stretches.map(s => 
            s.id === stretchId ? { ...s, image_url: response.data.image_url } : s
          )
        };
        setWorkout(updatedWorkout);
      } catch (error) {
        setError('Failed to regenerate image. Please try again.');
        console.error('Error regenerating image:', error);
      } finally {
        setRegeneratingStretches(prev => ({ ...prev, [stretchId]: false }));
      }
    }
  };

  if (error) {
    return <Error message={error} />;
  }

  if (!workout) {
    return <div>Loading...</div>;
  }

  if (isWorkoutStarted && workout) {
    return <WorkoutFlow workout={workout} onFinish={() => setIsWorkoutStarted(false)} onComplete={handleCompleteWorkout} history={history} />;
  }

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Workout Detail</h2>
      
      <div className="bg-white shadow-md rounded-lg p-6 mb-6 relative">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
          <h3 className="text-xl md:text-2xl font-semibold mb-2 md:mb-0 flex items-center">
            <FontAwesomeIcon icon="clock" className="mr-2 text-xl md:text-2xl" />
            <span className="text-lg md:text-xl">Total Duration: </span>
            <span className="text-lg md:text-xl font-bold ml-1">{Math.round(workout.duration / 60)} minutes</span>
          </h3>
          {workout.completed_at && (
            <>
              <span className="hidden md:inline-flex text-green-600 bg-green-100 px-3 py-1 rounded-full text-sm">
                <FontAwesomeIcon icon="check-circle" className="mr-1" />
                Completed
              </span>
              <FontAwesomeIcon icon="check-circle" className="text-green-600 md:hidden" />
            </>
          )}
        </div>
        <h4 className="text-xl font-semibold mb-4">Stretches:</h4>
        <ul className="space-y-6">
          {workout.stretches.map((stretch, index) => (
            <li key={stretch.id} className="bg-gray-50 rounded-lg p-4 shadow flex flex-col md:flex-row relative">
              {!workout.completed_at && (
                <button 
                  onClick={() => handleChangeStretch(index)} 
                  className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 z-10 bg-white rounded-full p-1 shadow-md text-xs md:text-base md:top-4 md:right-4"
                >
                  <FontAwesomeIcon icon="exchange-alt" />
                </button>
              )}
              {stretch.image_url && (
                <div className="w-full md:w-1/3 mb-4 md:mb-0 md:mr-4 relative">
                  <img src={stretch.image_url} alt={stretch.name} className="w-full h-64 md:h-48 object-contain rounded-md" />
                  {regeneratingStretches[stretch.id] ? (
                    <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 rounded-md">
                      <span className="text-white font-semibold">Regenerating...</span>
                    </div>
                  ) : (
                    <button 
                      onClick={() => handleRegenerateImage(stretch.id)}
                      className="absolute bottom-1 right-1 bg-white rounded-full p-1 shadow-md hover:bg-gray-100 transition-colors duration-200 text-xs"
                      title="Regenerate Image"
                    >
                      <FontAwesomeIcon icon="sync-alt" className="text-primary-600" />
                    </button>
                  )}
                </div>
              )}
              <div className="flex-1">
                <div className="flex items-center mb-2">
                  <span className="text-2xl font-bold text-blue-600 mr-3">{index + 1}</span>
                  <h4 className="text-xl font-semibold text-gray-800">{stretch.name}</h4>
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <p className="text-gray-600">
                    <FontAwesomeIcon icon="person" className="mr-2" fixedWidth />
                    <strong>Body Part:</strong>
                  </p>
                  <p className="text-gray-600">{stretch.body_part}</p>
                  <p className="text-gray-600">
                    <FontAwesomeIcon icon="clock" className="mr-2" fixedWidth />
                    <strong>Duration:</strong>
                  </p>
                  <p className="text-gray-600">{stretch.duration} seconds</p>
                </div>
                <p className="text-gray-700 mt-2">{stretch.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <ChangeStretchModal 
        isOpen={isChangeModalOpen}
        onClose={() => setIsChangeModalOpen(false)}
        onStretchChanged={handleStretchChanged}
        currentStretch={workout.stretches[selectedStretchIndex]}
      />
      <div className="flex justify-between mt-6">
        <button onClick={() => setIsWorkoutStarted(true)} className="btn btn-primary">
          <FontAwesomeIcon icon="play" className="mr-0 md:mr-2" />
          <span className="hidden md:inline">Start Workout</span>
        </button>
        <Link to={getBackDestination()} className="btn btn-secondary">
          <FontAwesomeIcon icon="arrow-left" className="mr-0 md:mr-2" />
          <span className="hidden md:inline">{getBackButtonText()}</span>
        </Link>
      </div>
    </div>
  );
}

export default WorkoutDetail;