import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api, { deleteWorkout } from '../services/api';
import WorkoutFilters from '../components/workoutfilters';
import Error from '../components/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp, faChevronDown, faTrashAlt, faPerson, faClock, faCalendarAlt, faCheckCircle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronUp, faChevronDown, faTrashAlt, faPerson, faClock, faCalendarAlt, faCheckCircle, faHourglassHalf);

function Workouts() {
  const [workouts, setWorkouts] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [filterCompleted, setFilterCompleted] = useState('all');
  const [filterBodyPart, setFilterBodyPart] = useState('');
  const [bodyParts, setBodyParts] = useState([]);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  useEffect(() => {
    fetchWorkouts();
    fetchBodyParts();
  }, []);

  const fetchWorkouts = async () => {
    try {
      setIsLoading(true);
      const response = await api.get('/workouts');
      setWorkouts(response.data);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to fetch workouts. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBodyParts = async () => {
    try {
      const response = await api.get('/body-parts');
      setBodyParts(response.data);
    } catch (error) {
      console.error('Failed to fetch body parts:', error);
    }
  };

  const filteredWorkouts = workouts
    ? workouts.filter(workout => {
        const completedFilter = filterCompleted === 'all' || 
          (filterCompleted === 'completed' && workout.completed_at) ||
          (filterCompleted === 'not_completed' && !workout.completed_at);
        const bodyPartFilter = !filterBodyPart || workout.body_parts.includes(filterBodyPart);
        return completedFilter && bodyPartFilter;
      })
    : [];

  const handleDeleteWorkout = async (id) => {
    if (window.confirm('Are you sure you want to delete this workout?')) {
      try {
        await deleteWorkout(id);
        fetchWorkouts();
      } catch (error) {
        setError('Failed to delete workout. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  const clearFilters = () => {
    setFilterCompleted('all');
    setFilterBodyPart('');
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Your Workouts</h2>
      {error && <Error message={error} />}
      <WorkoutFilters 
        isFiltersVisible={isFiltersVisible}
        setIsFiltersVisible={setIsFiltersVisible}
        filterCompleted={filterCompleted}
        setFilterCompleted={setFilterCompleted}
        filterBodyPart={filterBodyPart}
        setFilterBodyPart={setFilterBodyPart}
        bodyParts={bodyParts}
        clearFilters={clearFilters}
      />
      {filteredWorkouts.length === 0 ? (
        <p className="text-gray-600 text-lg mb-8">No workouts match your filters.</p>
      ) : (
        <ul className="space-y-4 mb-8">
          {filteredWorkouts.map((workout) => (
            <li 
              key={workout.id} 
              className="bg-white shadow-md rounded-lg p-6 hover:shadow-xl transition duration-300 cursor-pointer relative overflow-hidden group"
              onClick={() => history.push(`/workouts/${workout.id}`)}
            >
              <div className="flex flex-col">
                <div className="text-lg font-semibold text-primary-600 mb-2">
                  <FontAwesomeIcon icon="person" className="mr-2" />
                  {workout.body_parts.join(', ')}
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">
                    <FontAwesomeIcon icon="clock" className="mr-2" />
                    {Math.round(workout.duration / 60)} minutes
                  </span>
                </div>
                <div className="text-gray-400 text-sm mt-2">
                  <FontAwesomeIcon icon="calendar-alt" className="mr-2" />
                  {new Date(workout.created_at).toLocaleDateString()}
                </div>
              </div>
              <div className="absolute top-2 right-2 text-sm">
                {workout.completed_at ? (
                  <>
                    <span className="hidden md:inline-flex text-green-600 bg-green-100 px-2 py-1 rounded-full items-center">
                      <FontAwesomeIcon icon="check-circle" className="mr-1" />
                      Completed
                    </span>
                    <FontAwesomeIcon icon="check-circle" className="text-green-600 md:hidden" />
                  </>
                ) : (
                  <>
                    <span className="hidden md:inline-flex text-yellow-600 bg-yellow-100 px-2 py-1 rounded-full items-center">
                      <FontAwesomeIcon icon="hourglass-half" className="mr-1" />
                      Not Completed
                    </span>
                    <FontAwesomeIcon icon="hourglass-half" className="text-yellow-600 md:hidden" />
                  </>
                )}
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteWorkout(workout.id);
                }}
                className="absolute bottom-2 right-2 text-red-600 hover:text-red-800 transition-colors duration-300 md:opacity-50 md:hover:opacity-100"
              >
                <FontAwesomeIcon icon="trash-alt" />
              </button>
              {workout.completed_at ? (
                <div className="absolute top-0 left-0 w-full h-1 bg-green-500"></div>
              ) : (
                <div className="absolute top-0 left-0 w-full h-1 bg-yellow-500"></div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Workouts;