import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function WorkoutFilters({ 
  isFiltersVisible, 
  setIsFiltersVisible, 
  filterCompleted, 
  setFilterCompleted, 
  filterBodyPart, 
  setFilterBodyPart, 
  bodyParts,
  clearFilters
}) {
  return (
    <div className="mb-6 bg-white rounded-lg shadow-md overflow-hidden">
      <button
        onClick={() => setIsFiltersVisible(!isFiltersVisible)}
        className="w-full bg-primary-700 hover:bg-primary-800 text-grey px-4 py-3 flex items-center justify-between transition duration-300"
      >
        <span className="flex items-center">
          <FontAwesomeIcon icon={faFilter} className="mr-2" />
          Filters
        </span>
        <FontAwesomeIcon icon={isFiltersVisible ? faChevronUp : faChevronDown} />
      </button>
      {isFiltersVisible && (
        <div className="p-4">
          <div className="space-y-4">
            <div>
              <label htmlFor="filterCompleted" className="block text-sm font-medium text-gray-700 mb-1">
                Status
              </label>
              <select
                id="filterCompleted"
                value={filterCompleted}
                onChange={(e) => setFilterCompleted(e.target.value)}
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
              >
                <option value="all">All Workouts</option>
                <option value="completed">Completed</option>
                <option value="not_completed">Not Completed</option>
              </select>
            </div>
            <div>
              <label htmlFor="filterBodyPart" className="block text-sm font-medium text-gray-700 mb-1">
                Body Part
              </label>
              <select
                id="filterBodyPart"
                value={filterBodyPart}
                onChange={(e) => setFilterBodyPart(e.target.value)}
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
              >
                <option value="">All Body Parts</option>
                {bodyParts.map(part => (
                  <option key={part} value={part}>{part}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={clearFilters}
              className="text-primary-600 hover:text-primary-800 transition duration-300 flex items-center text-sm"
            >
              <FontAwesomeIcon icon={faTimes} className="mr-2" />
              Clear Filters
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WorkoutFilters;