import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import Error from '../components/error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CreateWorkout() {
  const [stretches, setStretches] = useState([]);
  const [selectedStretches, setSelectedStretches] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);
  const [selectedBodyPart, setSelectedBodyPart] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  useEffect(() => {
    fetchStretches();
    fetchBodyParts();
  }, []);

  const fetchStretches = async () => {
    try {
      const response = await api.get('/stretches');
      setStretches(response.data);
    } catch (error) {
      setError('Failed to fetch stretches. Please try again later.');
    }
  };

  const fetchBodyParts = async () => {
    try {
      const response = await api.get('/body-parts');
      setBodyParts(response.data);
    } catch (error) {
      setError('Failed to fetch body parts. Please try again later.');
    }
  };

  const handleStretchToggle = (stretch) => {
    setSelectedStretches(prevSelected => 
      prevSelected.some(s => s.id === stretch.id)
        ? prevSelected.filter(s => s.id !== stretch.id)
        : [...prevSelected, stretch]
    );
  };

  const handleBodyPartChange = (e) => {
    setSelectedBodyPart(e.target.value);
  };

  const handleCreateWorkout = async () => {
    if (selectedStretches.length === 0) {
      setError('Please select at least one stretch for your workout.');
      return;
    }

    try {
      const workoutData = {
        stretches: selectedStretches.map(s => ({ id: s.id, duration: s.duration })),
        duration: selectedStretches.reduce((total, s) => total + s.duration, 0)
      };
      const response = await api.post('/workouts', workoutData);
      history.push(`/workouts/${response.data.id}`);
    } catch (error) {
      setError('Failed to create workout. Please try again.');
    }
  };

  const filteredStretches = selectedBodyPart
    ? stretches.filter(s => s.body_part === selectedBodyPart)
    : stretches;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Create Custom Workout</h2>
      {error && <Error message={error} />}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="bodyPart">
          Filter by Body Part:
        </label>
        <select
          id="bodyPart"
          value={selectedBodyPart}
          onChange={handleBodyPartChange}
          className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          <option value="">All Body Parts</option>
          {bodyParts.map(part => (
            <option key={part} value={part}>{part}</option>
          ))}
        </select>
      </div>
      <ul className="space-y-4 mb-6">
        {filteredStretches.map(stretch => (
          <li key={stretch.id} className="flex items-center bg-white shadow-md rounded-lg p-4">
            <input
              type="checkbox"
              id={`stretch-${stretch.id}`}
              checked={selectedStretches.some(s => s.id === stretch.id)}
              onChange={() => handleStretchToggle(stretch)}
              className="mr-4 h-5 w-5"
            />
            <label htmlFor={`stretch-${stretch.id}`} className="flex-grow">
              <h3 className="text-lg font-semibold">{stretch.name}</h3>
              {stretch.image_url && (
                <img 
                  src={stretch.image_url} 
                  alt={stretch.name} 
                  className="w-32 h-32 object-contain rounded-md my-2" 
                />
              )}
              <p className="text-gray-600">Body Part: {stretch.body_part}</p>
              <p className="text-gray-600">Duration: {stretch.duration} seconds</p>
            </label>
          </li>
        ))}
      </ul>
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold">
          Total Duration: {selectedStretches.reduce((total, s) => total + s.duration, 0)} seconds
        </p>
        <button onClick={handleCreateWorkout} className="btn btn-primary">
          <FontAwesomeIcon icon="plus" className="mr-2" />
          Create Workout
        </button>
      </div>
    </div>
  );
}

export default CreateWorkout;