import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getCompletedWorkouts, deleteWorkoutCompletion } from '../services/api';
import Error from '../components/error';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fas);

function CompletedWorkouts() {
  const [completedWorkouts, setCompletedWorkouts] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchCompletedWorkouts();
  }, []);

  const fetchCompletedWorkouts = async () => {
    try {
      setIsLoading(true);
      const response = await getCompletedWorkouts();
      if (response.data === null) {
        setCompletedWorkouts([]);
      } else if (Array.isArray(response.data)) {
        setCompletedWorkouts(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setError('Unexpected response format from server');
      }
    } catch (error) {
      console.error('Error fetching completed workouts:', error);
      console.error('Error details:', error.response);
      setError(error.response?.data?.message || 'Failed to fetch completed workouts. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCompletion = async (id) => {
    if (window.confirm('Are you sure you want to delete this workout completion?')) {
      try {
        await deleteWorkoutCompletion(id);
        fetchCompletedWorkouts();
      } catch (error) {
        setError('Failed to delete workout completion. Please try again.');
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Completed Workouts</h2>
      {error && <Error message={error} />}
      {completedWorkouts.length === 0 && !error ? (
        <p className="text-gray-600 text-lg mb-8">You haven't completed any workouts yet.</p>
      ) : (
        <ul className="space-y-4 mb-8">
          {completedWorkouts.map((workout) => (
            <li 
              key={`${workout.id}-${workout.completed_at}`} 
              className="bg-white shadow-md rounded-lg p-6 hover:shadow-xl transition duration-300 relative overflow-hidden group cursor-pointer"
              onClick={() => history.push(`/workouts/${workout.workout_id}`, { from: '/completed-workouts' })}
            >
              <div className="flex flex-col">
                <div className="text-lg font-semibold text-primary-600 mb-2">
                  <FontAwesomeIcon icon="person" className="mr-2" />
                  {workout.body_parts.join(', ')}
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">
                    <FontAwesomeIcon icon="clock" className="mr-2" />
                    {Math.round(workout.duration / 60)} minutes
                  </span>
                </div>
                <div className="text-gray-400 text-sm mt-2">
                  <FontAwesomeIcon icon="calendar-alt" className="mr-2" />
                  Completed on {new Date(workout.completed_at).toLocaleString()}
                </div>
              </div>
              <div className="absolute top-2 right-2 text-sm">
                <span className="hidden md:inline-flex text-green-600 bg-green-100 px-2 py-1 rounded-full items-center">
                  <FontAwesomeIcon icon="check-circle" className="mr-1" />
                  Completed
                </span>
                <FontAwesomeIcon icon="check-circle" className="text-green-600 md:hidden" />
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteCompletion(workout.id);
                }}
                className="absolute bottom-2 right-2 text-red-600 hover:text-red-800 transition-colors duration-300 md:opacity-50 md:hover:opacity-100"
              >
                <FontAwesomeIcon icon="trash-alt" />
              </button>
              <div className="absolute top-0 left-0 w-full h-1 bg-green-500"></div>
            </li>
          ))}
        </ul>
      )}
      <Link to="/workouts" className="btn btn-primary">
        <FontAwesomeIcon icon="arrow-left" className="mr-0 md:mr-2" />
        <span className="hidden md:inline">Back to Workouts</span>
      </Link>
    </div>
  );
}

export default CompletedWorkouts;