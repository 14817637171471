import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { isAuthenticated, removeToken } from '../services/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Header() {
  const authenticated = isAuthenticated();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleLogout = () => {
    removeToken();
    history.push('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const navItems = [
    { to: '/', label: 'Home', icon: 'home' },
    { to: '/workouts', label: 'Workouts', icon: 'person', authRequired: true },
    { to: '/generate-workout', label: 'Generate', icon: 'magic', authRequired: true },
    { to: '/create-workout', label: 'Create', icon: 'plus', authRequired: true },
    { to: '/completed-workouts', label: 'Completed', icon: 'check-circle', authRequired: true },
    { to: '/stretches', label: 'Stretches', icon: 'running', authRequired: true },
  ];

  return (
    <header className="bg-gray-800 text-gray-100 shadow-lg">
      <nav className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <img src="/logo.png" alt="Meowbility Logo" className="h-10 w-10 mr-2" />
            <Link to="/" className="text-2xl font-extrabold text-babyblue-400">Meowbility</Link>
          </div>
          <div className="relative" ref={menuRef}>
            <button onClick={toggleMenu} className="flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-babyblue-300 transition duration-300">
              <FontAwesomeIcon icon="bars" className="mr-2" />
              Menu
            </button>
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                {navItems.map((item) => (
                  (!item.authRequired || authenticated) && (
                    <Link
                      key={item.to}
                      to={item.to}
                      onClick={closeMenu}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >
                      <FontAwesomeIcon icon={item.icon} className="mr-2" />
                      {item.label}
                    </Link>
                  )
                ))}
                {authenticated ? (
                  <button
                    onClick={() => { handleLogout(); closeMenu(); }}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <FontAwesomeIcon icon="sign-out-alt" className="mr-2" />
                    Logout
                  </button>
                ) : (
                  <Link
                    to="/login"
                    onClick={closeMenu}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <FontAwesomeIcon icon="sign-in-alt" className="mr-2" />
                    Login
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;