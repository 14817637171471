import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api, { getBodyParts } from '../services/api';
import Error from '../components/error';

function GenerateWorkout() {
  const [bodyParts, setBodyParts] = useState([]);
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);
  const [duration, setDuration] = useState(5);
  const [error, setError] = useState('');
  const history = useHistory();

  useEffect(() => {
    const fetchBodyParts = async () => {
      try {
        const response = await getBodyParts();
        setBodyParts(response.data);
      } catch (error) {
        setError('Failed to fetch body parts. Please try again later.');
      }
    };

    fetchBodyParts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    if (selectedBodyParts.length === 0) {
      setError('Please select at least one body part.');
      return;
    }
    try {
      const response = await api.post('/generate', { body_parts: selectedBodyParts, duration: duration * 60 });
      history.push(`/workouts/${response.data.id}`);
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to generate workout. Please try again.');
    }
  };

  const handleBodyPartChange = (e) => {
    const value = e.target.value;
    setSelectedBodyParts(prev => 
      prev.includes(value) 
        ? prev.filter(bp => bp !== value)
        : [...prev, value]
    );
  };

  return (
    <div className="max-w-md mx-auto card">
      <h2>Generate New Workout</h2>
      {error && <Error message={error} />}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block mb-2 font-bold text-gray-700">Body Parts:</label>
          <div className="grid grid-cols-2 gap-4">
            {bodyParts.map(bodyPart => (
              <div key={bodyPart} className="flex items-center">
                <input
                  type="checkbox"
                  id={bodyPart}
                  value={bodyPart}
                  checked={selectedBodyParts.includes(bodyPart)}
                  onChange={handleBodyPartChange}
                  className="mr-2 h-5 w-5 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                />
                <label htmlFor={bodyPart} className="text-gray-700">{bodyPart}</label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <label className="block mb-2 font-bold text-gray-700">
            Duration (minutes):
            <input
              type="number"
              value={duration}
              onChange={(e) => setDuration(parseInt(e.target.value))}
              min="1"
              step="1"
              className="input"
            />
          </label>
        </div>
        <button type="submit" className="btn btn-primary w-full">
          Generate Workout
        </button>
      </form>
    </div>
  );
}

export default GenerateWorkout;